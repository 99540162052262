<template>
  <TopNav></TopNav>
  <h1>Burç Hesaplama</h1>
  <fieldset>
    <legend>Burç Hesaplama Aracı</legend>
    <label>Doğum Gününüz:</label>
    <select name="gun" v-model.number="gun">
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
      <option value="7">7</option>
      <option value="8">8</option>
      <option value="9">9</option>
      <option value="10">10</option>
      <option value="11">11</option>
      <option value="12">12</option>
      <option value="13">13</option>
      <option value="14">14</option>
      <option value="15">15</option>
      <option value="16">16</option>
      <option value="17">17</option>
      <option value="18">18</option>
      <option value="19">19</option>
      <option value="20">20</option>
      <option value="21">21</option>
      <option value="22">22</option>
      <option value="23">23</option>
      <option value="24">24</option>
      <option value="25">25</option>
      <option value="26">26</option>
      <option value="27">27</option>
      <option value="28">28</option>
      <option value="29">29</option>
      <option value="30">30</option>
      <option value="31">31</option>
    </select>
    <select name="ay" v-model="ay">
      <option value="ocak">Ocak</option>
      <option value="subat">Şubat</option>
      <option value="mart">Mart</option>
      <option value="nisan">Nisan</option>
      <option value="mayis">Mayıs</option>
      <option value="haziran">Haziran</option>
      <option value="temmuz">Temmuz</option>
      <option value="agustos">Ağustos</option>
      <option value="eylul">Eylül</option>
      <option value="ekim">Ekim</option>
      <option value="kasim">Kasim</option>
      <option value="aralik">Aralık</option>
    </select>
    <br><br>
    Burcunuz: <span class="blue">{{ burc() }}</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Burc",
  data() {
    return {
      gun: 1,
      ay: "ocak"
    }
  },
  components: {
    TopNav
  },
  methods: {
    burc: function () {
      if ((this.ay == "ocak" && this.gun >= 21) || (this.ay == "subat" && this.gun <= 19)) {
        return "Kova";
      } else if ((this.ay == "subat" && this.gun >= 20) || (this.ay == "mart" && this.gun <= 20)) {
        return "Balık";
      } else if ((this.ay == "mart" && this.gun >= 21) || (this.ay == "nisan" && this.gun <= 20)) {
        return "Koç";
      } else if ((this.ay == "nisan" && this.gun >= 21) || (this.ay == "mayis" && this.gun <= 21)) {
        return "Boğa";
      } else if ((this.ay == "mayis" && this.gun >= 22) || (this.ay == "haziran" && this.gun <= 21)) {
        return "İkizler";
      } else if ((this.ay == "haziran" && this.gun >= 22) || (this.ay == "temmuz" && this.gun <= 22)) {
        return "Yengeç";
      } else if ((this.ay == "temmuz" && this.gun >= 23) || (this.ay == "agustos" && this.gun <= 22)) {
        return "Aslan";
      } else if ((this.ay == "agustos" && this.gun >= 23) || (this.ay == "eylul" && this.gun <= 23)) {
        return "Başak";
      } else if ((this.ay == "eylul" && this.gun >= 24) || (this.ay == "ekim" && this.gun <= 23)) {
        return "Terazi";
      } else if ((this.ay == "ekim" && this.gun >= 24) || (this.ay == "kasim" && this.gun <= 22)) {
        return "Akrep";
      } else if ((this.ay == "kasim" && this.gun >= 23) || (this.ay == "aralik" && this.gun <= 22)) {
        return "Yay";
      } else if ((this.ay == "aralik" && this.gun >= 23) || (this.ay == "ocak" && this.gun <= 20)) {
        return "Oğlak";
      } else {
        return "";
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

label {
  display: inline-block;
  min-width: 170px;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
